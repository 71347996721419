@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap);
html {
    height: '100%';
    /* overflow: hidden; */
}

body {
    height: '100%';
    overflow: auto;
    overscroll-behavior-y: contain;
    -webkit-overflow-scrolling: touch;
}

.MuiSpeedDialAction-staticTooltipLabel{
    background-color: transparent !important;
    box-shadow: none !important;
    border: none !important;
    color: white !important;
    margin: 0 !important;
    padding: 0 !important;
}

/* Breathing animation */
@-webkit-keyframes breathing {
    0% {
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
    }
    25% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    60% {
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
    }
    100% {
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
    }
}

@keyframes breathing {
    0% {
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
    }
    25% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    60% {
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
    }
    100% {
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
    }
}

